import $ from 'jquery'

export default class Naviation {
    constructor() {
        this.states = {
            active: 'is-active',
            delayed: 'delayed',
            persist: 'sub-menu--persisted',
            navActive: 'navigation--is-active',
            subNavActive: 'sub-menu--is-active',
        }

        this.classes = {
            offcanvas: 'c-offcanvas--menu',
            navicon: 'c-navicon',
            menu: 'menu',
            menuItems: 'menu__item',
            menuItemsHasChildren:'menu__item--has-children' ,
            subMenu: 'sub-menu',
            subMenuBackButton: 'close-sub-menu',
        }

        this.elements = {
            body: $('body'),
            navicon: $(`.${this.classes.navicon}`),
            offcanvas: $(`.${this.classes.offcanvas}`),
            menuItems: $(`.${this.classes.offcanvas} .${this.classes.menu}`).find(`> .${this.classes.menuItems}`),
            subMenuActivator: $(`.${this.classes.offcanvas} .${this.classes.menu}`).find(`.${this.classes.menuItemsHasChildren} > a`),
            subMenus: $(`.${this.classes.offcanvas} .${this.classes.menu}`).find(`.${this.classes.menuItemsHasChildren}`).find(`.${this.classes.subMenu}`),
            subMenuItems: $(`.${this.classes.offcanvas} .${this.classes.menu}`).find(`.${this.classes.menuItemsHasChildren}`).find(`.${this.classes.subMenu} > .${this.classes.menuItems}`),
            delayedItems: $(`.${this.classes.offcanvas} .${this.classes.subMenu} .${this.classes.menuItems}, .${this.classes.offcanvas} .${this.classes.subMenuBackButton}`),
            // * appeneded close button(s)
            subMenuClose: $(`<button class="${this.classes.subMenuBackButton}" aria-label="Back button" role="button"><span>Back</span></button>`)
        }

        this.appendBackButtons()
        this.events()
    }

    appendBackButtons() {
        this.elements.subMenuClose.prependTo(this.elements.subMenus)
    }

    events() {
        $(document).on('click', `.${this.classes.navicon}`, () => { // * due to prepended element has to fire on document applied trigger
            if(this.elements.body.hasClass(this.states.navActive)) return this.reset(true)

            this.elements.body.addClass(this.states.navActive)
            this.applyDelays()
        })

        $(document).on('click', `.${this.classes.subMenuBackButton}`, (e) => {
            e.preventDefault()
            this.reset()
        })

        this.elements.subMenuActivator.on('click', (e) => {
            this.toggleSubNavs(e)
        })
    }

    applyDelays() {        
        const subMenu = this.classes.subMenu
        const subMenuClose = this.classes.subMenuBackButton
        
        this.elements.menuItems.each(function(i) {
            let setDelay = `${(.6 + (i * .1))}s`            
            let hasSubMenu = Boolean($(this).find(`.${subMenu}`))            
            
            $(this).css('transition-delay', setDelay)
            
            if(!hasSubMenu) return 

            let lastItem = $(this).find(`.${subMenu} > li`).length -1

            $(this).find(`.${subMenu} > li`).each(function(x) {
                let setSubDelay = `${(.7 + (x * .1))}s`
                $(this).css('transition-delay', setSubDelay)
        
                if(x !== lastItem) return

                let setLastDelay = '.6s'                    
                $(this).siblings(`.${subMenuClose}`).css('transition-delay', setLastDelay)
            })           
        })
    }

    toggleSubNavs(e) {
        e.preventDefault()

        // * adds classes
        if(!this.elements.body.hasClass(this.states.subNavActive)) return $(() => {
            this.applyDelays()
            this.elements.body.addClass(this.states.subNavActive)
            $(e.target).siblings(`.${this.classes.subMenu}`).addClass(this.states.active)
        })

        // * switches between sub-navs - and removes other active sub-menus
        if(!$(e.target).siblings(`.${this.classes.subMenu}`).hasClass(this.states.active)) return $(() => {
            this.elements.body.addClass(this.states.persist)

            $(e.target).parent().siblings(`.${this.classes.menuItemsHasChildren}`).children(`.${this.classes.subMenu}.${this.states.active}`).removeClass(this.states.active)
            $(e.target).siblings(`.${this.classes.subMenu}`).addClass(this.states.active)
        })

        // * defaults to remove classes
        this.elements.body.removeClass(`${this.states.subNavActive} ${this.states.persist}`)
        $(e.target).siblings(`.${this.classes.subMenu}`).removeClass(this.states.active).parent()
    }

    reset($closeNavigation = false) {
        this.elements.body.removeClass(`${this.states.subNavActive} ${this.states.persist}`)
        this.elements.subMenus.removeClass(this.states.active)

        this.elements.delayedItems.each(function(i) {
            $(this).css('transition-delay', '0s')
        })
        
        if(!$closeNavigation) return

        this.elements.body.removeClass(`${this.states.navActive}`)
    }
};

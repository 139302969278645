import $ from 'jquery'
import Naviation from "./modules/Navigation";

new Naviation()

$(function() {
    $('.c-booking-form__heading').on('click', function (e) {
        $('.c-booking-form').toggleClass('is-active');

        e.preventDefault();
    });
})